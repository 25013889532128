import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"

export default () => 
<div>
    <SEO title={'Security Checklist for Software Developers | ICON Worldwide'} 
    description="Security should be foreseen as part of the system from the very beginning. Looking for a valuable security checklist? Find it on our blog!"
    canonical={'https://icon-worldwide.com/blog/security-checklist-for-developers'} />

    <NewsHeader/>

    <div id="single-news">
        <h1><span>Security</span> Checklist for Developers</h1>
        <div id="title-underline"></div>
        <div id="single-news-texts">
            <h2>Security Checklist for Software Developers</h2>
            <p>Security should be foreseen as part of the system from the very beginning, not added as a layer at the end. The latter solution produces insecure code (tricky patches instead of neat solutions), may limit functionality and will cost much more (in time/money).</p>
            <p>Here are some hints and tips for various phases of the software development lifecycle. This list certainly doesn’t contain all pieces of advice that software engineers should follow. It just includes the most important ones – those which are definitely worth keeping in mind when designing and developing almost any kind of software with any programming language!</p>
            <h3>General:</h3>
            <ul>
                <li>THINK OF THE SECURITY IMPLICATIONS OF WHAT YOUR CODE DOES;</li>
                <li>READ AND FOLLOW GUIDELINES FOR YOUR PROGRAMMING LANGUAGE AND SOFTWARE TYPE;</li>
                <li>REUSE TRUSTED CODE (LIBRARIES, MODULES ETC.);</li>
                <li>WRITE GOOD-QUALITY, READABLE AND MAINTAINABLE CODE (BAD CODE WON’T EVER BE SECURE).</li>
            </ul>
            <h3>Architecture:</h3>
            <ul>
                <li>MODULARITY: DIVIDE THE PROGRAM INTO SEMI-INDEPENDENT PARTS (SMALL, WELL-DEFINED INTERFACES TO EACH MODULE/FUNCTION);</li>
                <li>ISOLATION: EACH PART SHOULD WORK CORRECTLY EVEN IF OTHERS FAIL (RETURN WRONG RESULTS, SEND REQUESTS WITH INVALID ARGUMENTS);</li>
                <li>DEFENSE IN DEPTH: BUILD MULTIPLE LAYERS OF DEFENSE INSTEAD OF TRUSTING JUST ONE PROTECTION MECHANISM. FOR EXAMPLE: VALIDATE USER INPUT DATA AT ENTRY POINT, AND CHECK AGAIN ALL VALUES THAT ARE PASSED TO SENSITIVE PARTS OF THE CODE (LIKE FILE HANDLING ETC.);</li>
                <li>SIMPLICITY: COMPLEX SOLUTIONS ARE MUCH MORE LIKELY TO BE INSECURE.</li>
            </ul>
            <h3>Design:</h3>
            <ul>
                <li>MAKE SECURITY-SENSITIVE PARTS OF YOUR CODE SMALL;</li>
                <li>LEAST PRIVILEGE PRINCIPLE: DON’T REQUIRE MORE PRIVILEGES THAN YOU NEED. FOR EXAMPLE: RUN YOUR CODE AS THE LEAST PRIVILEGED USER NECESSARY (DON’T RUN IT AS ROOT, NOR WITH SUID FLAG). MAKE SURE THAT THE ACCOUNT ON WHICH YOU WILL RUN YOUR CODE HAS ONLY THE FILE ACCESS AND EXECUTE PRIVILEGES THAT YOUR CODE REALLY NEEDS. DON’T CONNECT TO A DATABASE WITH ADMIN PRIVILEGES FROM YOUR SOFTWARE;</li>
                <li>CHOOSE SAFE DEFAULTS: FOR EXAMPLE, A RANDOM PASSWORD THAT USERS ARE LIKELY TO CHANGE RATHER THAN A STANDARD DEFAULT PASSWORDS THAT MANY WON’T BOTHER TO CHANGE;</li>
                <li>DENY BY DEFAULT: FOR EXAMPLE, WHEN VALIDATING USER INPUT ACCEPT ONLY CHARACTERS THAT YOU EXPECT RATHER THAN TRYING TO BLOCK KNOWN “BAD” CHARACTERS;</li>
                <li>LIMIT RESOURCE CONSUMPTION, TO LIMIT THE LIKELIHOOD OR IMPACT OF A DENIAL OF SERVICE ATTACK;</li>
                <li>FAIL SECURELY: FOR EXAMPLE, IF THERE IS A RUNTIME ERROR WHEN CHECKING USER’S ACCESS RIGHTS, ASSUME SHE HAS NONE;</li>
                <li>IN DISTRIBUTED OR WEB APPLICATIONS DON’T TRUST THE CLIENT: DON’T EXPECT IT TO VALIDATE USER INPUT, PERFORM SECURITY CHECKS OR AUTHENTICATE USERS – IT ALL HAS TO BE DONE (AGAIN) ON THE SERVER SIDE; REMEMBER THAT HTTP RESPONSE HEADER FIELDS (COOKIES, USER-AGENT, REFERRER ETC.) AND HTTP QUERY STRING VALUES (FROM HIDDEN FIELDS OR EXPLICIT LINKS) MAY BE FORGED/MANIPULATED;</li>
                <li>CRYPTOGRAPHY: USE TRUSTED, PUBLIC ALGORITHMS, PROTOCOLS AND PRODUCTS. DO NOT INVENT YOUR OWN CRYPTOGRAPHIC ALGORITHMS OR PROTOCOLS, NOR IMPLEMENT EXISTING ONES – REUSE TRUSTED CODE.</li>
            </ul>
            <h3>Coding:</h3>
            <ul>
                <li>DON’T TRUST INPUT DATA: DATA COMING FROM POTENTIALLY MALICIOUS USERS IS THE SINGLE MOST COMMON REASON OF SECURITY-RELATED INCIDENTS (BUFFER OVERFLOW, SQL INJECTION, CROSS SITE SCRIPTING (XSS), CODE INSIDE DATA ETC.). INPUT DATA INCLUDES COMMAND-LINE ARGUMENTS, CONFIGURATION FILES (IF ACCESSIBLE BY NOT-TRUSTED USERS), ENVIRONMENT VARIABLES, COOKIES AND POST/GET ARGUMENTS ETC;</li>
                <li>VALIDATE ALL INPUT DATA: CONSIDER ALL INPUT DANGEROUS UNTIL PROVEN VALID, DENY BY DEFAULT IF NOT SURE, VALIDATE AT DIFFERENT LEVELS, FOR EXAMPLE AT INPUT DATA ENTRY POINT AND BEFORE REALLY USING THAT DATA;</li>
                <li>DON’T MAKE ANY ASSUMPTIONS ABOUT THE ENVIRONMENT: MAKE SURE YOUR CODE DOESN’T BREAK WITH MODIFIED/MALICIOUS PATH, CLASSPATH AND OTHERS ENVIRONMENT VARIABLES, CURRENT DIRECTORY, @INC PERL VARIABLE, UMASK, SIGNALS, OPEN FILE DESCRIPTORS ETC;</li>
                <li>BEWARE OF RACE CONDITION: CAN YOUR CODE RUN PARALLEL? WHAT IF SOMEONE EXECUTES TWO INSTANCES OF YOUR PROGRAM AT THE SAME TIME, OR CHANGES ENVIRONMENT IN THE MIDDLE OF ITS EXECUTION?</li>
                <li>DEAL WITH ERRORS AND EXCEPTIONS: DON’T ASSUME THAT EVERYTHING WILL WORK (ESPECIALLY FILE OPERATIONS, SYSTEM AND NETWORK CALLS), CATCH EXCEPTIONS, CHECK RESULT CODES; DON’T DISPLAY INTERNAL ERROR MESSAGES, FAILING SQL QUERY, STACK TRACE ETC;</li>
                <li>FAIL GRACEFULLY: IF THERE IS AN UNEXPECTED ERROR THAT YOU CAN’T RECOVER FROM, THEN LOG DETAILS, ALERT THE ADMINISTRATOR, CLEAN THE SYSTEM (DELETE TEMPORARY FILES, CLEAR MEMORY) AND INFORM THE USER;</li>
                <li>PROTECT PASSWORDS AND SECRET INFORMATION: DON’T HARD-CODE PASSWORDS (IT’S HARD TO CHANGE THEM AND EASY TO DISCLOSE), USE EXTERNAL FILES INSTEAD (POSSIBLY ENCRYPTED) OR ALREADY EXISTING CREDENTIALS (LIKE CERTIFICATES), OR SIMPLY ASK THE USER FOR THE PASSWORD;</li>
                <li>BE CAREFUL WHEN HANDLING FILES: IF YOU WANT TO CREATE IT, REPORT AN ERROR IF IT ALREADY EXISTS; WHEN YOU CREATE IT, SET FILE PERMISSIONS; IF YOU OPEN A FILE TO READ DATA, DON’T ASK FOR WRITE ACCESS; CHECK IF THE FILE YOU OPEN IS NOT A LINK WITH THE LSTAT() FUNCTION (BEFORE AND AFTER OPENING THE FILE); USE ABSOLUTE PATHNAMES (FOR BOTH COMMANDS AND FILES); BE EXTRA CAREFUL WHEN THE FILENAME (OR PART OF IT) COMES FROM A USER;</li>
                <li>TEMPORARY FILES: AVOID THEM WHENEVER POSSIBLE. PIPES ARE A SAFER AND MORE EFFICIENT WAY OF COMMUNICATING INFORMATION BETWEEN PROCESSES. IF YOU REALLY NEED REMPORARY FILES, DON’T FALL FOR THE SYMBOLIC LINK ATTACK (SOMEONE GUESSES THE NAME OF YOUR TEMPORARY FILE, AND CREATES A LINK FROM IT TO ANOTHER FILE E.G. /BIN/BASH, THAT YOUR PROGRAM OVERWRITES). TEMPORARY FILES MUST HAVE UNIQUE NAMES THAT ARE HARD TO GUESS! (USE TMPFILE() FOR C/C++, MKTEMP SHELL COMMAND ETC.);</li>
                <li>BE CAREFUL WITH SHELL CALLS, EVAL FUNCTIONS ETC.: SUCH FUNCTIONS EVALUATE THE STRING ARGUMENT AS CODE AND INTERPRET IT, OR RUN IT ON THE SHELL. IF AN ATTACKER MANAGED TO INJECT MALICIOUS INPUT TO THAT ARGUMENT, YOU’RE EXECUTING HIS CODE.</li>
            </ul>
            <h2>After the Implementation:</h2>
            <ul>
                <li>REVIEW YOUR CODE AND LET OTHERS REVIEW IT;</li>
                <li>WHEN A (SECURITY) BUG IS FOUND, SEARCH FOR SIMILAR ONES;</li>
                <li>USE TOOLS SPECIFIC TO YOUR PROGRAMMING LANGUAGE: BOUNDS CHECKERS, MEMORY TESTERS, BUG FINDERS ETC;</li>
                <li>TURN ON COMPILER / INTERPRETER WARNINGS AND READ THEM (PERL -W, GCC -WALL);</li>
                <li>DISABLE DEBUGGING INFORMATION (STRIP COMMAND, JAVAC -G:NONE, ETC.).</li>
            </ul>
            <p><a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.appdeveloperlisting.com/cross-platform-mobile-app-development-companies/">Top Cross Platform Mobile App Development Companies</a></p>
            <br></br>
            <br></br>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>

        <NewsFooter previous="the-future-of-your-customer-relationship" next="sofia-nearshore-development-team"/>
    </div>

    <Footer noScrollbar="true"/>
</div>